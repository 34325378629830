export const faqs = [
    {
      q: "What is Bubble Ai?",
      a: `Bubble Ai is an AI-powered virtual assistant running on the RA1 engine developed by Blue Spider
        Technologies Ltd. in Nigeria. It can perform various complex tasks such as creating cutting edge resumes/CVs, 
        tailored cover letters, easy job applications, ongoing and live meeting minute transcription, exceptional business 
        plans and proposals, as well as large audio and video transcription and translation in any language.`
    },
    {
      q: "Is there a free trial on the Resume creator?",
      a: `Yes, there is. As a first time user on bubble Ai, you get a glimpse of what I offer but with limited access. Proceed to try it for free to see how far you can get.`
    },
    {
        q: "Can I apply to jobs on Bubble Ai?",
        a: `YES! Premium users (subscribed to the PER WEEK and PER MONTH plans) get suggested high-paying jobs in locations of their choice (including remote) after creating and downloading any resume. These jobs are fitted to the created resume and gives you an over 90% chance of getting recruited.`
    },
    {
      q: "Can I upgrade or downgrade?",
      a: `For the Per Use plan, there is usually no need to upgrade as you naturally only get to use it once. 
        But if you wish to upgrade an existing plan, send us an email to support@bubble-ai.tech with your registered email stating exactly what you want and we will support your upgrade with a GOOD DISCOUNT.`
    },
    {
      q: "Is my personal info secure?",
      a: `YES! At Bubble Ai, we combine several state of the art cybersecurity measures including SSL/TLS (to encrypt your data in transit and prevent sniffing), Third party payment systems that are PCI DSS compliant and trusted by millions, regular security audits and most of all in-house ethical practices; all in the bid to maintain the highest level of user data security from development to daily handling of your data. Your privacy is a priority.`
    }
]