import refundImg from '../images/refund-stamp.png';

export const reviewDetails = [ 
    { 
        img: refundImg, 
        name: 'Chijioke', 
        review: 'The AI resume creator is amazing! It helped me create a professional resume in just a few minutes. Highly recommended.', 
        rating: 5 
    }, 
    // { 
    //     img: refundImg, 
    //     name: 'Sarah', 
    //     review: 'I used the AI Resume generator for my team meetings and it saved me a lot of time. The generated resume gave me the right skills, they were accurate as magic.', 
    //     rating: 4 
    // }, 
    { 
        img: refundImg, 
        name: 'John', 
        review: "I use the resume generator for my resume writing business, now I can generate more professional resumes in minutes. And in my business, more resumes mean more money. and all with little energy.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Emily', 
        review: "The AI resume creator is a lifesaver! It made the daunting task of creating a resume so much easier. The templates provided were modern and eye-catching. I would definitely recommend it to anyone in need of a professional resume.", 
        rating: 5 
    }, 
    // { 
    //     img: refundImg, 
    //     name: 'Wale', 
    //     review: "The AI meeting minutes generator is fantastic. It accurately captures all the important points discussed in the meeting and generates well-structured minutes. It has made my job as a secretary much easier. I would rate it 4 out of 5.", 
    //     rating: 4 
    // }, 
    { 
        img: refundImg, 
        name: 'Lisa', 
        review: "The Job Suggestions after the resume creation were quite what I needed, it was like 3 platforms in one making the process easier. Really great tool, It could use more descrition though.", 
        rating: 4 
    }, 
    { 
        img: refundImg, 
        name: 'Mike', 
        review: "The AI resume creator is a game-changer. It helped me create a visually appealing and well-structured resume. The AI's suggestions for keywords and skills were spot-on. I would highly recommend it to job seekers. Rating: 5 out of 5.", 
        rating: 5 
    }, 
    { 
        img: refundImg, 
        name: 'Jessica', 
        review: "It is good because you can really get job in days or few weeks depending on your drive.", 
        rating: 4 
    }, 
    // { 
    //     img: refundImg, 
    //     name: 'David Wilson', 
    //     review: "The AI business plan creator is a must-have for startups. It provides a structured approach to creating a business plan and offers valuable insights. The templates are professional and customizable. I would rate it 5 out of 5.", 
    //     rating: 5 
    // }, 
    { 
        img: refundImg, 
        name: 'Aisha', 
        review: "Wow, it gave me a CV with wonderful job descriptions and metrics that i could edit to fit my specific case. cover letter was tailored to my needs too. Nice project.", 
        rating: 5 
    }, 
    // { 
    //     img: refundImg, 
    //     name: 'Mark Johnson', 
    //     review: "The AI meeting minutes generator is a valuable tool for our team. It saves us a lot of time and ensures that all the important points are captured accurately. I would rate it 4 out of 5.", 
    //     rating: 4 
    // }, 
    // { 
    //     img: refundImg, 
    //     name: 'Emma Davis', 
    //     review: "The AI business plan creator is a game-changer for entrepreneurs. It provides a step-by-step guide and offers valuable suggestions. The templates are professional and customizable. I would highly recommend it. Rating: 5 out of 5.", 
    //     rating: 5 
    // }, 
    { 
        img: refundImg, 
        name: 'Jay', 
        review: "The AI resume creator is a fantastic tool. It helped me create a professional resume that stands out. The AI's suggestions for keywords and skills were very helpful. I would give it a rating of 5 out of 5.", 
        rating: 5 
    }
];