import comingSoonImg from "../../images/soon.jpg"
import standardTempImg from "../../images/resume-standard.png";
import elephantImg from "../../images/swimming-elephant.png";
import radiantImg from "../../images/radiant.jpg";
import flyingFish from "../../images/flying-fish.png";

const carouselData = [
    {
        title: "Standard",
        image: standardTempImg
    },
    {
        title: "Radiant Moon",
        image: radiantImg
    },
    {
        title: "Swimming Elephant",
        image: elephantImg
    },
    {
        title: "Flying Fish",
        image: flyingFish
    },
    {
        title: "Water Train",
        image: comingSoonImg
    },
    {
        title: "Sinking Duck",
        image: comingSoonImg
    }
];

export default carouselData